<template>
  <MainLayout fluid>
      <LocationsTable />
  </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/Main'
import ItemTableTemplate from '@/components/ItemTableTemplate'
import ItemTable from '@/components/ItemTable'
import Location from '@/resources/Location'

const LocationsTable = {
  ...ItemTableTemplate,
  mixins: [ItemTable(Location)]
}

export default {
  components: { MainLayout, LocationsTable },
  metaInfo() {
    return {
      title: this.$t('locations.title'),
    }
  },
};
</script>